var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_delivery_order") } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    layout: "vertical",
                    form: _vm.form,
                    "label-col": { span: 8 },
                    "wrapper-col": { span: 14 }
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.pickingListNumber.label
                                )
                              }
                            },
                            [
                              _vm.isFromDashboard
                                ? _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.detailSO.pickingList &&
                                            _vm.detailSO.pickingList.length > 0
                                            ? _vm.detailSO.pickingList[0]
                                                .pickingListNumber
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ])
                                : _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.pickingListNumber
                                              .decorator,
                                          expression:
                                            "formRules.pickingListNumber.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRules.pickingListNumber.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.pickingListNumber
                                            .placeholder
                                        ),
                                        showSearch: true,
                                        "filter-option": false,
                                        loading: _vm.loading.pickingList,
                                        disabled: _vm.disabledData
                                      },
                                      on: {
                                        change: function(value) {
                                          if (value) {
                                            _vm.changeDropdown(
                                              value,
                                              "pickingList"
                                            )
                                          }
                                        },
                                        search: function(value) {
                                          return _vm.getPickingList(value)
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataListPickingList, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.referenceId }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "" + data.pickingListNo
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "" + data.pickingListNo
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                            ],
                            1
                          ),
                          _vm.showNumber == "so"
                            ? _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.salesOrderNumber.label
                                    )
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.salesOrderNumber
                                            .decorator,
                                        expression:
                                          "formRules.salesOrderNumber.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.formRules.salesOrderNumber.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.salesOrderNumber
                                          .placeholder
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showNumber == "wo"
                            ? _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.workOrderNumber.label
                                    )
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.workOrderNumber
                                            .decorator,
                                        expression:
                                          "formRules.workOrderNumber.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.formRules.workOrderNumber.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.workOrderNumber
                                          .placeholder
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showNumber == "ic"
                            ? _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.icNumber.label)
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.icNumber.decorator,
                                        expression:
                                          "formRules.icNumber.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.$t(_vm.formRules.icNumber.name),
                                      placeholder: _vm.$t(
                                        _vm.formRules.icNumber.placeholder
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.mode !== "create"
                            ? _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.deliveryOrderNumber.label
                                    )
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.deliveryOrderNumber
                                            .decorator,
                                        expression:
                                          "formRules.deliveryOrderNumber.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name:
                                        _vm.formRules.deliveryOrderNumber.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.deliveryOrderNumber
                                          .placeholder
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.deliveryOrderDate.label
                                )
                              }
                            },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.deliveryOrderDate.decorator,
                                    expression:
                                      "formRules.deliveryOrderDate.decorator"
                                  }
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.deliveryOrderDate.placeholder
                                  ),
                                  format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                                  disabled: _vm.disabledData,
                                  "show-time": ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.supplierExpedition.label
                                )
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value:
                                        _vm.formRules.supplierExpedition
                                          .decorator,
                                      expression:
                                        "formRules.supplierExpedition.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.supplierExpedition.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.supplierExpedition
                                        .placeholder
                                    ),
                                    disabled: _vm.disabledData,
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption
                                  },
                                  on: {
                                    change: function(value) {
                                      if (value) {
                                        _vm.changeDropdown(value, "supplier")
                                      }
                                    },
                                    search: function(value) {
                                      return _vm.getSupplierExpedition(
                                        "supplierData.supplierType~*Ekspedisi*_AND_supplier~true_AND_active~true_AND_firstName~*" +
                                          value +
                                          "*_OR_lastName~*" +
                                          value +
                                          "*",
                                        value
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.dataListSupplier, function(
                                  data,
                                  index
                                ) {
                                  return _c(
                                    "a-select-option",
                                    { key: index, attrs: { value: data.id } },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s("" + data.firstName) +
                                                " "
                                            )
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s("" + data.firstName) +
                                              " "
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.supplierAddressBillTo.label
                                )
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value:
                                        _vm.formRules.supplierAddressBillTo
                                          .decorator,
                                      expression:
                                        "formRules.supplierAddressBillTo.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name:
                                      _vm.formRules.supplierAddressBillTo.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.supplierAddressBillTo
                                        .placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    disabled: _vm.disabledData,
                                    "filter-option": _vm.filterOption
                                  }
                                },
                                _vm._l(
                                  _vm.dataListSupplierAddressBillTo,
                                  function(data, index) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: index,
                                        attrs: { value: data.address }
                                      },
                                      [
                                        _c(
                                          "a-tooltip",
                                          [
                                            _c("template", { slot: "title" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s("" + data.address) +
                                                  " "
                                              )
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s("" + data.address) +
                                                " "
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.truckNumber.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.truckNumber.decorator,
                                    expression:
                                      "formRules.truckNumber.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.truckNumber.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.truckNumber.placeholder
                                  ),
                                  disabled: _vm.disabledData,
                                  autocomplete: "off"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.driverName.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.driverName.decorator,
                                    expression: "formRules.driverName.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.driverName.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.driverName.placeholder
                                  ),
                                  disabled: _vm.disabledData,
                                  autocomplete: "off"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.journalNumber.id
                            ? _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.journalNumber.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "generaljournal.detail",
                                          params: { id: _vm.journalNumber.id }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        { attrs: { type: "link" } },
                                        [_vm._v(_vm._s(_vm.journalNumber.name))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.customerName.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.customerName.decorator,
                                    expression:
                                      "formRules.customerName.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.customerName.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.customerName.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.customerAddressBillTo.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.customerAddressBillTo
                                        .decorator,
                                    expression:
                                      "formRules.customerAddressBillTo.decorator"
                                  }
                                ],
                                attrs: {
                                  name:
                                    _vm.formRules.customerAddressBillTo.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.customerAddressBillTo
                                      .placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.customerAddressShipTo.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.customerAddressShipTo
                                        .decorator,
                                    expression:
                                      "formRules.customerAddressShipTo.decorator"
                                  }
                                ],
                                attrs: {
                                  name:
                                    _vm.formRules.customerAddressShipTo.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.customerAddressShipTo
                                      .placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.rentPeriod.label)
                              }
                            },
                            [
                              _c("a-range-picker", {
                                staticClass: "w-100",
                                attrs: {
                                  ranges: {
                                    Today: [_vm.moment(), _vm.moment()],
                                    "This Month": [
                                      _vm.moment(),
                                      _vm.moment().endOf("month")
                                    ]
                                  },
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  type: "date",
                                  disabled: true
                                },
                                on: { change: _vm.onRangePickerChange },
                                model: {
                                  value: _vm.rentDate,
                                  callback: function($$v) {
                                    _vm.rentDate = $$v
                                  },
                                  expression: "rentDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.phoneNumber.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.phoneNumber.decorator,
                                    expression:
                                      "formRules.phoneNumber.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.phoneNumber.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.phoneNumber.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: _vm.disabledData
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.customerPicName.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.customerPicName.decorator,
                                    expression:
                                      "formRules.customerPicName.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.customerPicName.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.customerPicName.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: _vm.disabledData
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.description.label)
                              }
                            },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.description.decorator,
                                    expression:
                                      "formRules.description.decorator"
                                  }
                                ],
                                attrs: {
                                  rows: 4,
                                  name: _vm.formRules.description.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.description.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: _vm.disabledData
                                }
                              }),
                              _c("small", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tc(
                                        "lbl_characters-x",
                                        _vm.form.getFieldValue("description")
                                          ? _vm.form.getFieldValue(
                                              "description"
                                            ).length
                                          : 0,
                                        {
                                          data: _vm.form.getFieldValue(
                                            "description"
                                          )
                                            ? _vm.form.getFieldValue(
                                                "description"
                                              ).length
                                            : 0
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.showConfirmation }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      )
                    ],
                    1
                  ),
                  _c("TableCustom", {
                    attrs: {
                      idtable: "table1",
                      dataSource: _vm.dataListItems,
                      columns: _vm.columnsTable,
                      scroll: { x: "calc(100% + 900px)", y: 700 },
                      paginationcustom: false,
                      defaultPagination: {
                        showTotal: function(total) {
                          return _vm.$t("lbl_total_items", { total: total })
                        },
                        showSizeChanger: true
                      },
                      handleInput: _vm.handleInput,
                      onSelectChange: _vm.onSelectChange,
                      selectedRowKeys: _vm.selectedRowKeys
                    },
                    on: { "on-addData": _vm.handleAddData }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.print,
                            disabled: _vm.disabledPrint
                          },
                          on: { click: _vm.print }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      ),
                      _vm.$can("create", "delivery-order")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disabledData,
                                loading: _vm.loading.isFormSubmitted
                              },
                              on: { click: _vm.save }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 720,
            title: _vm.$t("lbl_add_data"),
            showmodal: _vm.modalOpen,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            {
              attrs: { slot: "contentmodal", gutter: [16, 16] },
              slot: "contentmodal"
            },
            [
              _c(
                "a-button",
                {
                  staticClass: "mb-3",
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.handleAddNewAcc }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
              ),
              _c("TableCustom", {
                attrs: {
                  idtable: "table2",
                  dataSource: _vm.dataListItemsModal,
                  columns: _vm.columnsTableModal,
                  scroll: { x: "calc(100px + 50%)", y: 400 },
                  paginationcustom: false,
                  defaultPagination: true,
                  loading: _vm.loading.tableModal,
                  handleCheckBox: _vm.handleCheckBoxTable,
                  addNew: _vm.onAddNewTableModal,
                  handleSearchSelectTable: _vm.handleSearchSelectTableModal
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function($event) {
                      _vm.modalOpen = !_vm.modalOpen
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              ),
              _vm.$can("create", "delivery-order")
                ? _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary" },
                      on: { click: _vm.saveDataModal }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }